@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-[16px] text-content-secondary font-oxygen;
}

h1, h2, h3, h4, h5, h6 {
  @apply text-content;
}

html {
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6, p, span {
  @apply max-w-2xl;
}

.react-datepicker__input-container input {
  font-size: 14px;
  background-color: transparent;
  margin-bottom: 2%;
  width: 175px;
}

*:focus-visible {
  outline: none;
}

tspan {
  @apply text-s-sm xl:text-l-sm;
}

div.scrollable::-webkit-scrollbar-thumb {
  @apply bg-primary;
}

div.scrollable::-webkit-scrollbar {
  @apply h-0.5 w-0.5;
}

div.scrollable:hover::-webkit-scrollbar {
  @apply h-1 w-0.5;
}
